import { Button, IconButton } from "@material-tailwind/react";
import Cookies from "js-cookie";
import React from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { MdEmail, MdLock, MdPerson, MdPhone } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import countries from "../../static/countries.json";
import { fetcher } from "../../utils/authFetch";
import SocialLogin from "./SocialLogin";

const SignUpForm = () => {
  const [country, setCountry] = React.useState("United States");
  const [visible, setVisible] = React.useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  // Registration api
  const signUpAPI = async (data) => {
    try {
      const res = await fetcher.post("/api/auth/registration", data);
      return res?.data;
    } catch (error) {
      return Promise.reject(
        error?.response?.data?.message || "Something Went wrong"
      );
    }
  };

  const onSubmit = async (data) => {
    const selectedCountry = countries.find((item) => item.name == country);

    if (!selectedCountry) {
      setError("phone", {
        type: "manual",
        message: "Please select a valid country",
      });
      return;
    }

    const validPhoneLengths = Array.isArray(selectedCountry?.phone_length)
      ? selectedCountry.phone_length
      : [selectedCountry.phone_length];

    if (!validPhoneLengths.includes(data.phone.length)) {
      setError("phone", {
        type: "manual",
        message: "Please enter a valid phone number",
      });
      return;
    } else {
      setError("phone", null);
    }

    const newData = {
      fields: {
        Name: data?.name,
        Email: data?.email,
        Password: data?.password,
        Country: country,
        Country_code: selectedCountry?.phone_code,
        Mobile: data?.phone,
      },
    };

    toast.promise(
      signUpAPI(newData),
      {
        loading: "Account creating...",
        success: (data) => {
          Cookies.set("email", data?.data?.fields?.Email, { expires: 7 });
          Cookies.set("token", data?.data?.accessToken, { expires: 7 });
          Cookies.set("user", JSON.stringify(data?.data), { expires: 7 });
          if (data?.data?.fields?.Email_verified_at) {
            navigate("/user-guide");
          } else {
            navigate(`/email-verify`);
          }
          return data?.message || "SignUp successful";
        },
        error: (errorMessage) => errorMessage,
      },
      { id: "signup" }
    );
    reset();
  };

  //modified
  const sortedCountries = countries.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    } else {
      return 0;
    }
  });

  return (
    <div className="">
      <h2 className="text-center text-3xl font-semibold mb-4">SignUp</h2>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        {/* Name */}
        <div className="w-full relative">
          <MdPerson className="absolute left-3 top-4 text-xl" />
          <input
            type="text"
            placeholder="Your Full Name"
            className="p-3 pl-10 bg-gray-100 border-l-4 border-primary focus:outline-none w-full rounded"
            {...register("name", {
              required: {
                value: true,
                message: "Please enter your name",
              },
            })}
          />
          <label className="label">
            {errors?.name?.type === "required" && (
              <span className="text-red-500 text-xs">
                {errors.name.message}
              </span>
            )}
          </label>
        </div>
        {/* Email */}
        <div className="w-full relative my-4">
          <MdEmail className="absolute left-3 top-4 text-xl" />
          <input
            type="email"
            placeholder="Your Email address"
            className="p-3 pl-10 bg-gray-100 border-l-4 border-primary focus:outline-none w-full rounded"
            {...register("email", {
              required: { value: true, message: "Please provide your email" },
              pattern: {
                value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                message: "Please enter a valid email",
              },
            })}
          />
          <label className="label">
            {(errors?.email?.type === "required" ||
              errors?.email?.type === "pattern") && (
              <span className="text-red-500 text-xs">
                {errors.email.message}
              </span>
            )}
            {/*  */}
          </label>
        </div>

        {/* Password */}
        <div className="form-control w-full relative">
          <MdLock className="absolute left-3 top-4 text-xl" />
          <input
            type={visible ? "text" : "password"}
            placeholder="Password"
            className="p-3 pl-10 bg-gray-100 border-l-4 border-primary focus:outline-none w-full rounded"
            {...register("password", {
              required: { value: true, message: "Choose a strong password" },
              pattern: {
                // value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d])?.{8,}$/,
                message:
                  "Password should contain at least 8 characters, 1 uppercase, 1 lowercase and 1 number",
              },
            })}
          />
          <div className="absolute top-1 right-1">
            <IconButton
              variant="text"
              className="cursor-pointer text-xl rounded-full"
              onClick={() => setVisible(!visible)}
            >
              {visible ? <IoEye /> : <IoEyeOff />}
            </IconButton>
          </div>
          <label className="label">
            {(errors.password?.type === "required" ||
              errors?.password?.type === "pattern") && (
              <span className="text-red-500 text-xs">
                {errors.password.message}
              </span>
            )}
          </label>
        </div>

        {/* Country */}

        <div className=" rounded relative mt-4 bg-gray-100 border-l-4 border-primary px-4 w-[320px]">
          <select
            id="country"
            className="w-full p-3.5 bg-transparent outline-none"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          >
            {sortedCountries.map((country) => (
              <option key={country.name} value={country.name}>
                {country.name} ({country.phone_code})
              </option>
            ))}
          </select>
        </div>

        {/* Phone Number */}
        <div className="w-full relative mt-4">
          <MdPhone className="absolute left-3 top-4 text-xl" />
          <input
            type="text"
            placeholder="Your Phone Number"
            className="p-3 pl-10 bg-gray-100 border-l-4 border-primary focus:outline-none w-full rounded"
            {...register("phone", {
              required: {
                value: true,
                message: "Enter your Phone Number",
              },
            })}
          />
          <label className="label">
            {(errors?.phone?.type === "required" ||
              errors?.phone?.type === "manual") && (
              <span className="text-red-500 text-xs">
                {errors.phone.message}
              </span>
            )}
          </label>
        </div>

        <Button className="w-full bg-primary mt-4" type="submit">
          SignUp
        </Button>
      </form>

      <p className="block text-center mt-2 text-sm">
        Already have an account?{" "}
        <Link to="/login" className="text-secondary link-hover hover:underline">
          Login Now
        </Link>
      </p>

      {/* Divider */}
      <div className="flex items-center mt-4">
        <div className="border-t border-gray-900 w-full"></div>
        <p className="text-center px-4">OR</p>
        <div className="border-t border-gray-900 w-full"></div>
      </div>

      {/* Social Login */}
      <SocialLogin />
    </div>
  );
};

export default SignUpForm;
