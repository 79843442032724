import { useEffect, useState } from "react";
import { fetcher } from "../../utils/authFetch";
import toast from "react-hot-toast";
import useAllCategories from "../../hooks/useAllCategories";
import { useNavigate } from "react-router-dom";
import CreateTitle from "../../components/createBlogComponents/CreateTitle";
import QuillEditor from "../../components/createBlogComponents/QuillEditor";
import CreateSeoSection from "../../components/createBlogComponents/CreateSeoSection";
import CreateSlug from "../../components/createBlogComponents/CreateSlug";
import CreateCategory from "../../components/createBlogComponents/CreateCategory";
import CreateAuthor from "../../components/createBlogComponents/CreateAuthor";
import CreateTags from "../../components/createBlogComponents/CreateTags";
import CreateFeaturedImage from "../../components/createBlogComponents/CreateFeaturedImage";


const BlogForm = ({ data, isUpdate, singleBlogRefetch }) => {


    const currentData = data?.fields
    const currentId = data?.id

    // hooks
    const navigate = useNavigate();
    const [publishLoading, setPublishLoading] = useState(false);
    const [draftLoading, setDraftLoading] = useState(false);
    const [description, setDescription] = useState(null);
    const [image, setImage] = useState(null);
    const [slug, setSlug] = useState(null);
    const [author, setAuthor] = useState(null);
    const [metaDescription, setMetaDescription] = useState(null);
    const [category, setCategory] = useState(null);
    const [tags, setTags] = useState(null);
    const [title, setTitle] = useState(null);
    const [emptyFieldError, setEmptyFieldError] = useState(false);
    const [isDuplicateSlug, setDuplicateSlug] = useState(false);
    const [emptySlug, setEmptySlug] = useState("");

    // get category from custom hook
    const { categoriesLoading, allCategories, categoriesRefetch } = useAllCategories();

    useEffect(() => {
        if (currentData) {
            setTitle(currentData?.Title !== 'null' ? currentData?.Title : "" || "");
            setDescription(currentData?.Description !== 'null' ? currentData?.Description : "" || "");
            setSlug(currentData?.Slug !== 'null' ? currentData?.Slug : "" || "");
            setAuthor(currentData?.Author !== 'null' ? currentData?.Author : "" || "");
            setCategory(currentData?.Category !== 'null' ? currentData?.Category : "" || "");
            setTags(currentData?.Tags !== 'null' ? currentData?.Tags : "" || "");
            setMetaDescription(currentData?.MetaDescription !== 'null' ? currentData?.MetaDescription : "" || "");
            setImage(currentData?.Image !== 'null' ? currentData?.Image : "" || "");
        }
    }, [currentData])


    // handle field requirement check
    const validateField = () => {
        if (!description || !image || !slug || !author || !category || !tags || !title) {
            setEmptyFieldError(true);
            return false;
        }
        return true;
    }


    // handle success data posting functionalities
    const handlePublishSuccess = () => {
        toast.success("Blog posted successfully!");
        setPublishLoading(false);
        navigate("/admin/manageBlogs")
        setTitle('');
        setDescription('');
        setSlug('');
        setAuthor('');
        setCategory('');
        setTags('');
        setImage(null);
        // Trigger any necessary UI updates
        categoriesRefetch();
    }


    // handle success draft posting
    const handleDraftSuccess = () => {
        toast.success("Saved as draft!");
        setDraftLoading(false);
        // Trigger any necessary UI updates
        categoriesRefetch();
    }


    // publish blog
    const handlePublishArticle = (isDraft) => {

        const noEmptyField = !isDraft && validateField();
        if (!isDraft && !noEmptyField) {
            return;
        };
        if (!slug) {
            setEmptySlug("Slug is required");
            return;
        }

        const draft = isDraft;
        const formData = new FormData();
        formData.append('image', image);
        formData.append('title', title);
        formData.append('author', author);
        formData.append('description', description);
        formData.append('slug', slug);
        formData.append('tags', tags);
        formData.append('metaDescription', metaDescription);
        formData.append('category', category);
        formData.append('draft', draft);

        try {
            isDraft ? setDraftLoading(true) : setPublishLoading(true);

            // sending request to backend
            fetcher.post("/api/blogs", formData)
                .then(res => {
                    const data = res.data;
                    if (data.success && !isDraft) {
                        handlePublishSuccess()
                    }
                    if (data.success && isDraft) {
                        handleDraftSuccess()
                    }
                })
                .catch(error => {
                    if (error.response.data.message === "Slug already exists") {
                        setDuplicateSlug(true);
                    }
                    isDuplicateSlug ?
                        toast.error("Slug already exists! Please choose another slug.")
                        :
                        toast.error("Oops! Something went wrong.");
                    setPublishLoading(false)
                    setDraftLoading(false)
                })
        } catch (error) {
            toast.error("Oops! Something went wrong.");
            console.log("failed:", error)
            setPublishLoading(false);
            setDraftLoading(false)
        }
    }


    // draft update
    const handleDraftUpdate = () => {

        const draft = true;

        const formData = new FormData();
        formData.append('image', image);
        formData.append('title', title);
        formData.append('author', author);
        formData.append('description', description);
        formData.append('slug', slug);
        formData.append('tags', tags);
        formData.append('metaDescription', metaDescription);
        formData.append('category', category);
        formData.append('draft', draft);

        try {
            setDraftLoading(true);

            // sending request to backend
            fetcher.put(`/api/blogs/${currentId}`, formData)
                .then(res => {
                    const data = res.data;
                    if (data.success) {
                        handleDraftSuccess()
                    }
                })
                .catch(() => {
                    toast.error("Oops! Something went wrong.");
                    setDraftLoading(false)
                })
        } catch (error) {
            toast.error("Oops! Something went wrong.");
            console.log("failed:", error)
            setDraftLoading(false);
        }
    }



    // update blog
    const handleUpdateBlog = () => {


        const noEmptyField = validateField();
        if (!noEmptyField) {
            return;
        };

        const draft = false;
        const formData = new FormData();
        formData.append('image', image);
        formData.append('title', title);
        formData.append('author', author);
        formData.append('description', description);
        formData.append('slug', slug);
        formData.append('tags', tags);
        formData.append('metaDescription', metaDescription);
        formData.append('category', category);
        formData.append('draft', draft);

        try {
            setPublishLoading(true);

            // sending request to backend
            fetcher.put(`/api/blogs/${currentId}`, formData)
                .then(res => {
                    const data = res.data;
                    if (data.success) {
                        toast.success("Blog updated successfully!");
                        singleBlogRefetch();
                        setPublishLoading(false);
                        navigate("/admin/manageBlogs")
                    }
                })
                .catch(error => {
                    if (error.response.data.message === "Slug already exists") {
                        setDuplicateSlug(true);
                    }
                    isDuplicateSlug ?
                        toast.error("Slug already exists! Please choose another slug.")
                        :
                        toast.error("Oops! Something went wrong.");
                    setPublishLoading(false)
                })
        } catch (error) {
            toast.error("Oops! Something went wrong.");
            console.log("failed:", error)
            setPublishLoading(false);
        }
    }




    return (
        <div className="flex flex-col xl:flex-row justify-start lg:justify-between items-start gap-8">

            {/* Left div - React quill editor interface */}
            <div className="w-full xl:w-4/6 mt-10 flex flex-col justify-start items-start gap-5">

                {/* title */}
                <CreateTitle title={title} setTitle={setTitle} emptyFieldError={emptyFieldError} />

                {/* quill editor */}
                <QuillEditor description={description} setDescription={setDescription} emptyFieldError={emptyFieldError} />

                {/* seo part */}
                <div className="w-full flex flex-col justify-start items-start gap-4">
                    <CreateSeoSection metaDescription={metaDescription} setMetaDescription={setMetaDescription} />
                </div>

            </div>


            {/* Right div - other post options */}
            <div className="w-full xl:w-2/6 xl:min-h-[100vh] xl:border-l xl:px-5 flex flex-col-reverse xl:flex-col justify-start items-start">

                {/* publishing/updating button */}
                {
                    isUpdate ?
                        <button
                            onClick={handleUpdateBlog}
                            className="bg-primary hover:bg-primaryLighter duration-500 text-white px-4 py-2 font-medium w-full rounded-sm text-lg">
                            {publishLoading ? "Please wait" : `${currentData?.Draft ? "Publish" : "Update"}`}
                        </button>
                        :
                        <button
                            onClick={() => handlePublishArticle(false)}
                            className="bg-primary hover:bg-primaryLighter duration-500 text-white px-4 py-2 font-medium w-full rounded-sm text-lg">
                            {publishLoading ? "Please wait" : "Publish"}
                        </button>
                }

                {/* save draft button */}
                {
                    (isUpdate && currentData?.Draft) &&
                    <button
                        onClick={handleDraftUpdate}
                        className="bg-gray-500 hover:bg-gray-700 duration-500 text-white px-4 py-2 font-medium w-full rounded-sm text-lg my-4">
                        {draftLoading ? "Please wait" : "Save draft"}
                    </button>
                }
                {
                    !isUpdate &&
                    <button
                        onClick={() => handlePublishArticle(true)}
                        className="bg-gray-500 hover:bg-gray-700 duration-500 text-white px-4 py-2 font-medium w-full rounded-sm text-lg my-4">
                        {draftLoading ? "Please wait" : "Save draft"}
                    </button>
                }

                {/* author + category + slug */}
                <div className="w-full bg-white p-6 my-8 rounded-lg flex flex-col justify-start items-start gap-4 order-1">

                    {/* slug */}
                    <CreateSlug slug={slug} setSlug={setSlug} emptyFieldError={emptyFieldError} isDuplicateSlug={isDuplicateSlug} title={title} isUpdate={isUpdate} emptySlug={emptySlug} setEmptySlug={setEmptySlug} />


                    {/* category */}
                    <CreateCategory categoriesLoading={categoriesLoading} allCategories={allCategories} setCategory={setCategory} category={category} emptyFieldError={emptyFieldError} />

                    {/* author  */}
                    <CreateAuthor author={author} setAuthor={setAuthor} emptyFieldError={emptyFieldError} />

                    {/* featured image */}
                    <CreateFeaturedImage image={image} setImage={setImage} emptyFieldError={emptyFieldError} />

                    {/* Tags */}
                    <CreateTags tags={tags} setTags={setTags} emptyFieldError={emptyFieldError} />

                </div>

            </div>

        </div>
    );
};

export default BlogForm;