import { useParams } from "react-router-dom";
import useSingleBlog from "../../hooks/useSingleBlog";
import BlogForm from "../../components/shared/BlogForm";
import Loading from "../../components/shared/Loading/Loading";


const UpdateBlog = () => {

    const { id } = useParams();
    const { singleBlogLoading, singleBlog, singleBlogRefetch } = useSingleBlog({ id });


    return (
        <>
            {
                singleBlogLoading ? <Loading className={"min-h-[600px]"} />
                    : <BlogForm data={singleBlog?.data?.records[0]} isUpdate={true} singleBlogRefetch={singleBlogRefetch} />
            }
        </>
    );
};

export default UpdateBlog;