const config = {
  server_url: process.env.REACT_APP_SERVER_URL || "https://api.betterreturn.net",
  // server_url: "http://localhost:3001",
  server2_url: process.env.REACT_APP_SERVER2_URL || "https://better-return-server.onrender.com",
  stripe_key: process.env.REACT_APP_STRIPE_KEY || "pk_live_51LDU3XCBxfPNT5xi8fHclpGPXXIsJhk3P2kuVGdFlRBgBDNGWapEfOTvkWZbvrqtSTZHvGAFrBxQuhOoVpADF7oX00NlJYBgTB",
  // stripe_key: "pk_test_51LDU3XCBxfPNT5xiRCQYMQX16yFf6yetoDfhnDaljRsyJEs3EWrsjlJSx0AO37466fiM7xDQ9oOj8ospI8IGZxLE00rm7hZbth",
  // server_url: "http://localhost:3001"
};

export default config;
